/*
 * MedGrocer logo only
 */
import React, { useState, useContext, Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { faBars, faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons"

import { AppContext } from "../../context/AppContext"

import styles from "./utils/layout.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Navbar = ({ path }) => {
  const data = useStaticQuery(graphql`
    {
      logoSquare: file(
        relativePath: { eq: "logos/medgrocer-square-white.png" }
      ) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const navbarItems = [
    {
      name: "Help Center",
      link: "/help-center",
      icon: faQuestion,
      isHiddenInDesktop: false,
    },
  ]

  const logoSquare = data.logoSquare.childImageSharp.fixed

  const { state } = useContext(AppContext)
  const [isMenuActive, setMenuActive] = useState(false)

  const handleMenuActive = () => setMenuActive(!isMenuActive)

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-light is-primary">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Link to="/" className="is-flex is-align-items-center">
            <span className={classNames(styles["navbar__logo"])}>
              <Img fixed={logoSquare} />
            </span>
            <span className="has-text-white has-text-weight-bold">
              {state?.websiteName}
            </span>
          </Link>
          <div className={styles["navbar__burgerContainer"]}>
            <a
              onClick={handleMenuActive}
              aria-label="menu"
              aria-expanded="false"
              className="icon"
            >
              <FontAwesomeIcon icon={isMenuActive ? faTimes : faBars} />
            </a>
          </div>
        </div>
        <div
          className={classNames(
            "navbar-menu",
            styles["navbar__menu"],
            { [styles["mobile__navigation"]]: isMenuActive },
            {
              "is-active": isMenuActive,
            }
          )}
        >
          <div className="navbar-end mr-1">
            {navbarItems.map((item) => (
              <Link
                to={item.link}
                className={item.class}
                onClick={() => setMenuActive(false)}
              >
                <span
                  className={classNames(
                    "navbar-item p-1",
                    styles["navbar__menuItem"],
                    { "is-hidden-desktop": item.isHiddenInDesktop }
                  )}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="is-hidden-desktop icon mr-1 has-text-primary"
                  />
                  {item.name}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </nav>
      <section
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
